import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, uploadString } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import logo from "./Dhaej-The-Dowry.png";
import "./App.css";

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyA_IRv1rWfXpjNu2347TPg0Zpp_b5CBhKA",
  authDomain: "fir-rtc-52fa9.firebaseapp.com",
  projectId: "fir-rtc-52fa9",
  storageBucket: "fir-rtc-52fa9.appspot.com",
  messagingSenderId: "513352459808",
  appId: "1:513352459808:web:f212db740ae3fc9a6a7e45",
  measurementId: "G-R9GG2Y6KWV",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

function Mains() {
  const [state, setState] = useState({
    name: "",
    email: "",
    age: "",
    img: null,
    num: "",
    occ: "",
    income: "",
    school: "",
    city: "",
    buz: "",
    gotra:"",
  });

  const [checkInp, setCheckInp] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newmodalIsOpen, setNewModalIsOpen] = useState(false);
  const [msgName, setMsgName] = useState("hidden");
  const [msgEmail, setMsgEmail] = useState("hidden");
  const [msg, setMsg] = useState("");

  const handleChange = (evt) => {
    const value = evt.target.value;
    
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleFileChange = (evt) => {
    setState({
      ...state,
      img: evt.target.files[0],
    });
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const validateForm = () =>
    state.name &&
    validateEmail(state.email) &&
    state.occ &&
    state.income &&
    state.age >= 18 &&
    state.num.length === 10 &&
    checkInp &&
    state.school &&
    state.city &&
    state.buz &&
    state.gotra;
    // state.img;

  const upload = () => {
    if (validateForm()) {
      const data = {
        name: state.name,
        email: state.email,
        age: state.age,
        number: state.num,
        occupation: state.occ,
        income: state.income,
        school: state.school,
        city: state.city,
        gotra: state.gotra,
        buz: state.buz,
      };

      const dataUrlString = `data:text/plain;base64,${btoa(JSON.stringify(data))}`;
      const dataRef = ref(storage, `${state.name + uuidv4()}`);
      const imageRef = ref(storage, `${state.name + uuidv4()}/${state.img}`);

      uploadString(dataRef, dataUrlString, "data_url")
        .then(() => {
          console.log("Uploaded data");
        })
        .catch((error) => {
          console.error("Data upload error:", error);
        });

      uploadBytes(imageRef, state.img)
        .then(() => {
          console.log("Image uploaded");
        })
        .catch((error) => {
          console.error("Image upload error:", error);
        });

      setTimeout(() => {
        callPopUp();
      }, 1300);
    } else {
      setNewModalIsOpen(true);
    }
  };

  const callPopUp = () => {
    document.querySelector(".form").reset();
    setModalIsOpen(true);
  };

  const sendMsg = () => {
    if (msg) {
      const messageData = {
        name: msgName,
        email: msgEmail,
        message: msg,
      };

      const dataUrlString = `data:text/plain;base64,${btoa(JSON.stringify(messageData))}`;
      const messageRef = ref(storage, `${msgName + "MESSAGE" + uuidv4()}`);

      uploadString(messageRef, dataUrlString, "data_url")
        .then(() => {
          alert("Message sent successfully");
        })
        .catch((error) => {
          console.error("Message upload error:", error);
        });

      document.querySelector(".form2").reset();
    } else {
      alert("Please type the message");
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    window.location.reload();
  };

  const closeNewModal = () => {
    setNewModalIsOpen(false);
  };

  const messages = [
    "Your Dahej total is below 0. Kindly, please change your Mindset.",
    "Get a degree, not Dowry. Real men don't need a dowry to feel valued.",
    "Be sensible and say no to Dowry.",
    "Why Dowry? Oh, maybe because you can't earn for yourself?",
    "Before: Dowry, Dowry, Dowry.  \nAfter Police Case: Sowry, Sowry, Sowry",
    "Isn't love enough, or are you shopping for a spouse?",
    "Wow, what a salary package though still asking for Dahej. What a Shame!",
    "Dowry: Because nothing says love like a price tag.",
    "Instead of taking Dahej, you should take skill-improving courses :D ",
    "Why ask for dowry? Can't handle a fair fight for respect?",
    "Dowry: The ultimate test of how low you can go.",
    "We're sorry you don't qualify for a Dahej. Kindly contact 112 for a quick profile overview.",
    "Your profile details are being sent to the Police Headquarters. To cancel, click close, and don't practice dowry.",
    "Congrats, you've earned a trip to the nearest Police Station. To claim, contact us at our given email.",
  "Dowry: Because true love apparently comes with a receipt.",
  "Who needs dowry when you've got self-respect?",
  "Dowry is so outdated. Try love and mutual respect instead.",
   
  "Dowry: The 'gift' that keeps on taking.",
  

  ];

  let randomMessage = Math.floor(Math.random() * 19);
  let ans = messages[randomMessage];
// console.log(randomMessage);

  


  return (
    <div className="Mains">
      <nav className="box">
        <img id="logo" src={logo} alt="Logo" />
      </nav>

      <div id="main">
         
    <div id="hero-section">

        <div id="about">
          <h2>About</h2>
          <p>
            Welcome to DahejTheDowry! We're glad you're here. Our <span id="dtd-span">
              DahejTheDowry
            calculator</span>
            &nbsp;is designed to provide you with the best results based on
            the details you provide. What we do is take your user details and use
            our backend to generate an accurate estimation of your DahejTheDowry.
            <br />
            <br />
            Before filling out the form, we recommend reading our Disclaimer and
            Terms & Conditions. This will ensure that you have a clear
            understanding of our policies.
            <br /> <br />
            If you have any questions or would like to get in touch with us,
            please don't hesitate to reach out to us at
            dahejthedowry@hotmail.com. Our team is always happy to assist you. Thank you for choosing DahejTheDowry!
          </p>
        </div>
        <div id="hero-img">
          <img src="/hero-img.jpg" alt="Couple hand in hand" />
        </div>
    </div>
<hr style={{width:"90%"}} />
<div id="form-container">

          <h2>Form</h2>
          <p>Please fill out all the information to get the best estimation for your Dowry. Don't hold back.</p>
          <p>*paisa hi paisa hogaaa babu bhaiyaa...*</p>
        <div id="mid">

        <div id="money-img">
        <img src="/Pehle gandhi fir shaadi.png" alt="" />
          </div>



          <div id="form-tag">          
          <form className="form">
            <div className="form-group">
              <label htmlFor="name">
                <sup>*</sup> Name:{" "}
              </label>
              <input
                type="text"
                id="name"
                required
                placeholder="Enter your Name"
                name="name"
                value={state.name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">
                <sup>*</sup> Email:{" "}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                required
                placeholder="Enter your Email "
                value={state.email}
                onChange={handleChange}
                 
                 
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">
                <sup>*</sup> Phone Number:{" "}
              </label>
              <input
                type="number"
                id="phone"
                name="num"
                required
                placeholder="Enter your Mobile Number  "
                value={state.num}
                onChange={handleChange}
                
                
              />
            </div>

            <div className="form-group">
              <label htmlFor="age">
                <sup>*</sup> Age:{"should be above 18"} 

              </label>
              <input
                type="number"
                id="age"
                name="age"
                required
                min={18}
                max={80}
                placeholder="Enter your Age  "
                value={state.age}
                onChange={handleChange}
                
                
                
              />
            </div>

            <div className="form-group">
              <label htmlFor="occupation">
                <sup>*</sup> Gotra:{" "}
              </label>
              <input
                type="text"
                id="gotra"
                name="gotra"
                required
                placeholder="Enter your Gotra "
                value={state.gotra}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="occupation">
                <sup>*</sup> Occupation:{" "}
              </label>
              <input
                type="text"
                id="occupation"
                name="occ"
                required
                placeholder="Enter your Occupation  "
                value={state.occ}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="income">
                <sup>*</sup> Salary Package:{" "}
              </label>
              <input
                type="number"
                id="income"
                name="income"
                required
                placeholder="Enter your Income  "
                value={state.income}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="school">
                <sup>*</sup> University:{" "}
              </label>
              <input
                type="text"
                id="school"
                name="school"
                required
                placeholder="Enter your School/College  "
                value={state.school}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="city">
                <sup>*</sup> City:{" "}
              </label>
              <input
                type="text"
                id="city"
                name="city"
                required
                placeholder="Enter your City  "
                value={state.city}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="business">
                <sup>*</sup> Business Title:{" "}
              </label>
              <input
                type="text"
                id="business"
                name="buz"
                required
                placeholder="Enter your Business Title  "
                value={state.buz}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="file">
                 Image:{" "}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                 
                onChange={handleFileChange}
              />
            </div>

            <div className="form-group"
            style={{display:"ruby-text"}}> 
              <input
                type="checkbox"
                id="checkbox"
                name="checkbox"
                value="check"
                onChange={() => setCheckInp(!checkInp)}
                />
              <label htmlFor="checkbox">
                {" "}
                <sup>*</sup>I agree to all Terms & Conditions
              </label>
            </div>
            <button type="button" className="btn" onClick={upload}>
              Submit
            </button>
          </form>
          </div>
          
        </div>
</div>


        <Modal
          id="modal"
          isOpen={newmodalIsOpen}
          onRequestClose={closeNewModal}
        >
          <h2>Please fill in all the required fields.</h2>
          <button onClick={closeNewModal}>Okay, let's fill them.</button>
        </Modal>
        <Modal id="modal" isOpen={modalIsOpen} onRequestClose={closeModal}>
          <div>
            <h3>Hello, Your report is ready</h3>
            <p>{ans}</p>
            <br></br>
            <i>
              Note:We raise awareness about dowry by generating these messages.
            </i>
            <br />
            <br />
            <em>For more details please reach us at 112</em>
            <button onClick={closeModal}>Close</button>
          </div>
        </Modal>


        <div id="message">
          <form className="form2">
          <h2>Get in touch with us</h2>
            <div className="form-group">
              <label htmlFor="name">Name: </label>
              <input
                type="text"
                id="msg-name"
                required
                placeholder="Enter your Name"
                name="msg-name"
                
                onChange={(e) => setMsgName(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email: </label>
              <input
                type="email"
                id="msg-email"
                name="msg-email"
                required
                placeholder="Enter your Email "
                 
                onChange={(e) => setMsgEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="msg"><sup >*</sup>Message: </label>
              <textarea
                id="msg"
                required
                placeholder="Type your Message"
                name="msg"
                 
                onChange={(e) => setMsg(e.target.value)}
              ></textarea>
            </div>

            <button type="button" className="btn" onClick={sendMsg}>
              Send
            </button>
          </form>
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <h2>Result</h2>
          <p>{ans}</p>
          <button onClick={closeModal}>Close</button>
        </Modal>

        <Modal
          isOpen={newmodalIsOpen}
          onRequestClose={closeNewModal}
          contentLabel="Example Modal"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <h2>Warning</h2>
          <p>Please fill all required fields correctly.</p>
          <button onClick={closeNewModal}>Close</button>
        </Modal>
      </div>

      <footer>
         
          
          <Link to="/Terms&Conditions" target="_blank">
            {" "}
            Terms & Conditions{" "}
          </Link>
           
            <Link to="/Disclaimer" target="_blank">Disclaimer</Link>
           <a href="mailto:dahejthedowry@hotmail.com" style={{wordBreak:'break-all'}}>Contact Us</a>
           
        <a href="https://www.linkedin.com/in/shubhainder/">Made by Shubhainder</a>

        <p>©2023-2024 DahejTheDowry</p>
      </footer>
    </div>
  );
}

export default Mains;
