import React from 'react';
import Mains from './Mains';
import { Route,Routes,Link } from 'react-router-dom';
import { Disc } from './tnc';
import Disclaimer from './Disclaimer';

export const App = () => {
  return (
    <div>
      {/* <Mains/> */}
      <Routes>
        <Route path='/' element={<Mains/>} />
        <Route path='/Disclaimer'  element={<Disclaimer/>}  />
        <Route path='/Terms&Conditions'  element={<Disc/>}  />
      </Routes>
    </div>
  )
} 

export default App




