import React from 'react'

function Disclaimer() {
  return (
    <div id='disc'> 
    <h2>
      Disclaimer: 
      </h2>
    We would like to emphasize that our project aims solely
    to spread awareness about the negative impact of dowry on
    individuals and society. We do not intend to hurt the sentiments of
    any individual, group or society in any way. Our goal is to
    encourage people to reject dowry and discourage its practice. We
    acknowledge that the issue of dowry is a sensitive and emotional one
    for many people, and we would like to clarify that any opinions
    expressed on our website are intended to promote positive change and
    not to cause harm or offense. We are committed to presenting
    information in a respectful and sensitive manner. However, we also
    recognize that despite our best efforts, there is a possibility that
    some individuals or groups may be offended by the information
    presented on our website. We want to assure our users that this is
    not our intention, and we sincerely apologize in advance if anyone
    feels hurt or offended by our content. We urge all users to exercise
    discretion and sensitivity when engaging with our website and to
    respect the diverse perspectives and experiences of others. We also
    encourage feedback from our users, and we are open to suggestions
    for improvement. In summary, our aim is to promote awareness and
    positive change regarding dowry, while being mindful of the
    potential impact on individuals and communities. We hope that our
    users will join us in this effort and help us to create a more just
    and equitable society for all.
    <br />
    Note: We do not support Dowry or it's practice.
  </div>
  )
}

export default Disclaimer