import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
 
import "react-router-dom";
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
// import { Disc } from './tnc';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 <BrowserRouter>


  <App />
 </BrowserRouter>
 

    );

  
